import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  sortConfig: { key: null, direction: 'asc' },
  tasks: [],
  activeFilter: 'all',
  searchQuery: ''
};

const calculateAiScore = (priority, dueDate) => {
  let score = 0;
  const today = new Date();
  const due = new Date(dueDate.replace(/-/g, '/'));
  const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

  // Priority factor
  switch (priority) {
    case 'Critical':
      score += 40;
      break;
    case 'High':
      score += 30;
      break;
    case 'Medium':
      score += 10;
      break;
    case 'Low':
      score += 5;
      break;
    default:
      score += 15;
  }

  // Due date factor
  if (daysUntilDue < 0) { // Overdue
    score += 70;
  } else if (daysUntilDue === 0) { // Due today
    score += 60;
  } else if (daysUntilDue === 1) { // Due tomorrow
    score += 50;
  } else if (daysUntilDue <= 4) { // Due soon
    score += 40;
  } else if (daysUntilDue <= 7) { // Due within a week
    score += 10;
  } else { // Due later
    score += 5;
  }

  // Add some randomness (±5)
  score += Math.floor(Math.random() * 10) - 5;

  // Ensure score stays within bounds
  return Math.max(1, Math.min(99, score));
};

const taskManagerSlice = createSlice({
  name: 'taskManager',
  initialState,
  reducers: {
    setSortConfig: (state, action) => {
      const { key } = action.payload;
      let direction = 'asc';
      if (state.sortConfig.key === key && state.sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      state.sortConfig = { key, direction };
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    addTask: (state, action) => {
      const aiScore = calculateAiScore(action.payload.priority, action.payload.dueDate);
      const teamNumber = action.payload.responsibleTeam;
      const newTask = {
        ...action.payload,
        id: Date.now(),
        initiatedBy: 'Demo Admin',
        status: aiScore > 80 ? 'error' : 'Pending',
        aiScore,
        createdAt: new Date().toISOString(),
        responsibleTeam: "Team #" + teamNumber,
        team: {
          name: `Team #${teamNumber}`,
          members: [
            { name: "Ahmed Ali", role: "Team Lead" },
            { name: "Fatima Zayed", role: "Civil engineer" },
            { name: "Hassan Khan", role: "Site technician" },
            { name: "Sara Noor", role: "Network operator" }
          ]
        }
      };
      state.tasks.unshift(newTask);
    },
    addActivity: (state, action) => {
      const relevantTask = state.tasks.find(task => 
        task.device_id === '#' + action.payload.deviceId
      );
      
      if (relevantTask) {
        if (!relevantTask.activities) {
          relevantTask.activities = [];
        }
        relevantTask.activities.push({
          description: action.payload.description,
          date: action.payload.date,
          status: action.payload.status
        });
      }
    },
    completeTask: (state, action) => {
      const task = state.tasks.find(t => t.id === action.payload);
      if (task) {
        task.status = 'completed';
        task.completedAt = new Date().toISOString();
        if (!task.activities) task.activities = [];
        task.activities.push({
          description: 'Task marked as completed',
          date: new Date().toLocaleDateString(),
          status: 'completed'
        });
      }
    },
    rescheduleTask: (state, action) => {
      const { taskId, newDate } = action.payload;
      const task = state.tasks.find(t => t.id === taskId);
      if (task) {
        task.dueDate = newDate;
        task.aiScore = calculateAiScore(task.priority, newDate);
        if (!task.activities) task.activities = [];
        task.activities.push({
          description: `Task rescheduled to ${newDate}`,
          date: new Date().toLocaleDateString(),
          status: 'pending'
        });
      }
    }
  }
});

export const { setSortConfig, setTasks, setActiveFilter, setSearchQuery, addTask, completeTask, addActivity, rescheduleTask } = taskManagerSlice.actions;

const filterTasksByDate = (tasks, filter) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  switch (filter) {
    case 'due-today':
      return tasks.filter(task => {
        const dueDate = new Date(task.dueDate.replace(/-/g, '/'));
        return dueDate.toDateString() === today.toDateString();
      });
    case 'overdue':
      return tasks.filter(task => {
        const dueDate = new Date(task.dueDate.replace(/-/g, '/'));
        return dueDate < today;
      });
    case 'upcoming':
      return tasks.filter(task => {
        const dueDate = new Date(task.dueDate.replace(/-/g, '/'));
        return dueDate > today;
      });
    default:
      return tasks;
  }
};

const filterTasksBySearch = (tasks, searchQuery) => {
  if (!searchQuery) return tasks;
  
  const query = searchQuery.toLowerCase();
  return tasks.filter(task => 
    task.object?.toLowerCase().includes(query) ||
    task.initiatedBy?.toLowerCase().includes(query) ||
    task.missionType?.toLowerCase().includes(query)
  );
};

export const selectFilteredAndSortedTasks = createSelector(
  [
    state => state.taskManager.tasks,
    state => state.taskManager.sortConfig,
    state => state.taskManager.activeFilter,
    state => state.taskManager.searchQuery
  ],
  (tasks, sortConfig, activeFilter, searchQuery) => {
    let filteredTasks = [...tasks];
    
    // Apply date filter
    filteredTasks = filterTasksByDate(filteredTasks, activeFilter);
    
    // Apply search filter
    filteredTasks = filterTasksBySearch(filteredTasks, searchQuery);
    
    // Apply sorting
    if (sortConfig.key) {
      filteredTasks.sort((a, b) => {
        const aValue = a[sortConfig.key] || '';
        const bValue = b[sortConfig.key] || '';
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    
    return filteredTasks;
  }
);

export default taskManagerSlice.reducer; 