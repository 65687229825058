import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFilter, setSearchQuery } from '../redux/taskManagerSlice';
import TaskTableHeader from './TaskTableHeader';
import TaskTableBody from './TaskTableBody';
import RegularButton from '../components/regular-button';
import IconCircleButton from '../components/icon-button';
import { PlusIcon } from '@heroicons/react/24/solid';
import CreateTaskModal from './CreateTaskModal';

const TaskManager = ({ onStartTasks }) => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(state => state.taskManager.activeFilter);
  const searchQuery = useSelector(state => state.taskManager.searchQuery);
  const tasks = useSelector(state => state.taskManager.tasks);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFilterClick = (filter) => {
    dispatch(setActiveFilter(filter));
  };

  const handleSearch = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    // The CreateTaskModal component will handle the form reset internally
  };

  return (
    <div className="p-0 m-6">
      {/* Navigation buttons */}
      <div className="flex gap-2 mb-4" id="task-manager-filtering-buttons">
        <div className="flex gap-2 flex-grow">
          <div className="w-24">
            <RegularButton
              text="All"
              selected={selectedFilter === 'all'}
              onClick={() => handleFilterClick('all')}
              colorScheme="light"
            />
          </div>
          <div className="w-32">
            <RegularButton
              text="Due today"
              selected={selectedFilter === 'due-today'}
              onClick={() => handleFilterClick('due-today')}
              colorScheme="light"
            />
          </div>
          <div className="w-32">
            <RegularButton
              text="Overdue"
              selected={selectedFilter === 'overdue'}
              onClick={() => handleFilterClick('overdue')}
              colorScheme="light"
            />
          </div>
          <div className="w-32">
            <RegularButton
              text="Upcoming"
              selected={selectedFilter === 'upcoming'}
              onClick={() => handleFilterClick('upcoming')}
              colorScheme="light"
            />
          </div>
        </div>

        {/* Action buttons */}
        <div className="flex gap-2">
          <div>
            <IconCircleButton
              text={`Start Tasks: ${tasks.length}`}
              colorScheme="light"
              onClick={onStartTasks}
            />
          </div>
          <div>
            <IconCircleButton
              text="Create task"
              colorScheme="light"
              onClick={handleOpenModal}
            />
          </div>
        </div>
      </div>
      {/* Search row */}
      <div className="flex gap-2 mb-4">
        <div className="flex-grow">
          <input
            type="text"
            placeholder="Search task title"
            value={searchQuery}
            onChange={handleSearch}
            className="w-[50rem] px-4 py-6 h-10 rounded-full border border-gray-200 focus:outline-none focus:border-[#051A36]"
          />
        </div>
        <div>
          <IconCircleButton
            text="Filter"
            icon={() => <PlusIcon className="w-6 h-6" />}
            colorScheme="light"
          />
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto h-[calc(100vh-14rem)] max-h-[calc(100vh-5rem)] overflow-y-auto border border-gray-200 rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <TaskTableHeader />
          <TaskTableBody />
        </table>
      </div>
      <CreateTaskModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default TaskManager; 