import { useSelector, useDispatch } from 'react-redux';
import { setCurrentMarkerType, saveChanges, setPushToEnvironmentDisabled } from '../../redux/markerMakerSlice';
import { setDevices } from '../../redux/devicesSlice';
import { setDistricts } from '../../redux/districtsSlice';
import { downloadJson, uploadJson } from '../../json_data/import_to_store';
import './Controls.css';
import validateJsonStructure from '../../utils/json_validator';

export default function Controls() {
  const dispatch = useDispatch();
  const { 
    currentMarkerType, 
    pushToEnvironmentDisabled,
    localDeviceMarkers,
    localDistrictMarkers,
    inspectorSaveDisabled
  } = useSelector(state => state.markerMaker);

  const handleSave = () => {
    dispatch(saveChanges());
    
    if (currentMarkerType === 'device') {
      dispatch(setDevices(localDeviceMarkers));
    } else {
      dispatch(setDistricts(localDistrictMarkers));
    }
    
    dispatch(setPushToEnvironmentDisabled(true));
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileContent = await file.text();
        console.log('File content:', fileContent);

        const jsonData = JSON.parse(fileContent);
        console.log('Parsed JSON:', jsonData);

        const validationErrors = validateJsonStructure(jsonData);
        if (validationErrors.length > 0) {
          alert(`JSON validation errors:\n${validationErrors.join('\n')}`);
          return;
        }

        const success = await uploadJson(file);
        if (success) {
          dispatch(setPushToEnvironmentDisabled(true));
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
        alert('Invalid JSON format');
      }
    }
  };

  const handleDownload = () => {
    if (!inspectorSaveDisabled) {
      const confirmSave = window.confirm("You have unsaved changes. Do you want to save them before downloading?");
      if (confirmSave) {
        handleSave();
      }
    }
    downloadJson();
  };

  return (
    <div id="controls">
      <div className="markerTypeGroup">
        <button 
          className={`markerButton ${currentMarkerType === 'device' ? 'active' : ''}`}
          onClick={() => dispatch(setCurrentMarkerType('device'))}
        >
          Device Marker
        </button>
        <button 
          className={`markerButton ${currentMarkerType === 'district' ? 'active' : ''}`}
          onClick={() => dispatch(setCurrentMarkerType('district'))}
        >
          District Marker
        </button>
      </div>
      <div className="jsonButtonGroup">
        <button 
          className="saveButton"
          disabled={pushToEnvironmentDisabled}
          onClick={handleSave}
        >
          Update Main JSON
        </button>
        <button 
          className="saveButton"
          onClick={handleDownload}
        >
          Download Main JSON
        </button>
        <label className="saveButton" style={{ cursor: 'pointer' }}>
          Upload Main JSON
          <input
            type="file"
            accept=".json"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
        </label>
      </div>
    </div>
  );
} 