import IconCircleButton from '../components/icon-button';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { PhoneIcon, EnvelopeIcon, CheckIcon, CalendarIcon } from '@heroicons/react/24/solid';
import DeviceInformation from '../device_cards/ai_card/DeviceInformation';
import { completeTask, rescheduleTask } from '../redux/taskManagerSlice';
import { addNotification } from '../redux/notificationSlice';
import RescheduleDateModal from './RescheduleDateModal';

const TaskBrowser = ({ onBack }) => {
  const tasks = useSelector(state => state.taskManager.tasks);
  const devices = useSelector(state => state.devices.devices);
  const dispatch = useDispatch();
  // Sort tasks by AI score in descending order
  const sortedTasks = [...tasks].sort((a, b) => b.aiScore - a.aiScore);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  
  const currentTask = sortedTasks[currentTaskIndex];
  const completedTasks = tasks.filter(task => task.status === 'completed').length;
  const totalTasks = tasks.length;
  const completionPercentage = (completedTasks / totalTasks) * 100;
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);

  const handlePreviousTask = () => {
    if (currentTaskIndex > 0) {
      setCurrentTaskIndex(currentTaskIndex - 1);
    }
  };

  const handleNextTask = () => {
    if (currentTaskIndex < sortedTasks.length - 1) {
      setCurrentTaskIndex(currentTaskIndex + 1);
    }
  };

  const currentDevice = devices.find(device => 
    device.sensor_id === currentTask?.device_id?.replace('#', '')
  );

  const handleComplete = () => {
    if (currentTask) {
      dispatch(completeTask(currentTask.id));
      dispatch(addNotification({
        message: 'Task marked as completed',
        type: 'success'
      }));
      
      // Check if this was the last task to be completed
      if (completedTasks + 1 === totalTasks) {
        dispatch(addNotification({
          message: '🎉 Congratulations! All tasks completed!',
          type: 'success'
        }));
      }
      
      handleNextTask();
    }
  };

  const handleReschedule = () => {
    setIsRescheduleModalOpen(true);
  };

  const handleSkip = () => {
    handleNextTask();
  };

  return (
    <>
      <div className="flex flex-col h-[calc(100vh-7rem)]">
        {/* Top navigation */}
        <div className="flex justify-between mb-4">
          <div>
            <IconCircleButton
              text="Back to list"
              onClick={onBack}
              colorScheme="light"
              circlePosition='left'
            />
          </div>
          {/* Right-aligned group */}
          <div className="flex items-center gap-2">
            <IconCircleButton
              text="Previous task"
              colorScheme="light"
              circlePosition='left'
              onClick={handlePreviousTask}
              disabled={currentTaskIndex === 0}
            />
            {/* Progress bar container */}
            <div className="w-[500px] shrink-0 h-full relative">
              <div className="relative flex items-center h-full">
                <div 
                  className="absolute left-0 h-full bg-status-success rounded-full transition-all duration-300 z-[1]"
                  style={{ width: `${completionPercentage}%` }}
                />
                <div className="flex items-center h-full w-full rounded-full border border-[#051A36] z-[2]">
                  <div className="w-full text-center text-lg text-[#051A36]">
                    Task completed: {completedTasks}/{totalTasks}
                    {completedTasks === totalTasks && ' 🎉'}
                  </div>
                </div>
              </div>
            </div>
            <IconCircleButton
              text="Next task"
              colorScheme="light"
              circlePosition='right'
              onClick={handleNextTask}
              disabled={currentTaskIndex === sortedTasks.length - 1}
            />
          </div>
        </div>

        {/* Main content area */}
        <div className="flex-grow border border-gray-200 rounded-lg overflow-hidden flex flex-col">
          {/* Title bar */}
          <div className="bg-[#051A36] text-white p-4">
            <div className="flex items-center gap-8">
              <div>
                <h2 className="text-xl">{currentTask?.object || 'No task selected'}</h2>
                <div className="flex items-center gap-2 mt-1">
                  <span className="text-sm text-gray-300">
                    {currentTask?.responsibleTeam || 'No team assigned'}
                  </span>
                  <div className="flex gap-2">
                    <button className="w-6 h-6 rounded-full bg-white flex items-center justify-center">
                      <PhoneIcon className="w-3.5 h-3.5 text-[#051A36]" />
                    </button>
                    <button className="w-6 h-6 rounded-full bg-white flex items-center justify-center">
                      <EnvelopeIcon className="w-3.5 h-3.5 text-[#051A36]" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-2 min-w-[200px]">
                <div className="text-sm flex gap-2">
                  <span className="text-gray-300">Due date:</span>
                  <span className={currentTask?.overdue ? 'text-red-500' : ''}>{currentTask?.dueDate}</span>
                </div>
                <div className="text-sm flex gap-2">
                  <span className="text-gray-300">Priority:</span>
                  <span className={`text-${getPriorityColor(currentTask?.priority)}`}>{currentTask?.priority}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 ml-auto">
                <IconCircleButton
                  text="Reschedule"
                  colorScheme="light"
                  circlePosition='left'
                  icon={CalendarIcon}
                  onClick={handleReschedule}
                />
                <IconCircleButton
                  text="Skip"
                  colorScheme="light"
                  circlePosition='right'
                  onClick={handleSkip}
                />
                <IconCircleButton
                  text="Complete"
                  colorScheme="light"
                  circlePosition='right'
                  icon={CheckIcon}
                  onClick={handleComplete}
                  disabled={currentTask?.status === 'completed'}
                />
              </div>
            </div>
          </div>
          
          {/* Content area */}
          <div className="bg-gray-400 p-4 flex-1 overflow-y-auto min-h-0">
            <div className="flex flex-col gap-4">
              {/* Device Information Card */}
              {currentDevice ? (
                <DeviceInformation 
                    device={currentDevice} 
                    showCloseButton={false} 
                    logTaskActivity={true} 
                />
              ) : (
                <div className="bg-white rounded-lg p-4 shadow-sm">
                  <p className="text-gray-500">Device information not available</p>
                </div>
              )}

              {/* Recent Activities Section */}
              <div className="bg-white rounded-lg p-4 shadow-sm">
                <h3 className="text-lg font-semibold mb-3">Recent activities</h3>
                <div className="flex flex-col gap-2">
                  {currentTask?.activities?.map((activity, index) => (
                    <div key={index} className="flex items-center justify-between py-2 px-4 rounded-lg border border-dark-blue">
                      <span className="text-md">{activity.description}</span>
                      <div className="flex items-center gap-2">
                        <span className="text-md text-gray-500">{activity.date}</span>
                        {activity.status === 'pending' ? (
                          <div className="w-5 h-5 rounded-full bg-yellow-500" />
                        ) : (
                          <div className="w-5 h-5 rounded-full bg-green-500 flex items-center justify-center">
                            <CheckIcon className="w-3 h-3 text-white" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Team Section */}
              <div className="bg-white rounded-lg p-4 shadow-sm">
                <table className="w-full">
                  <thead className="text-sm bg-gray-200">
                    <tr>
                      <th className="text-left py-2 px-4 font-bold">Team</th>
                      <th className="text-left py-2 px-4 font-bold">Team members</th>
                      <th className="text-left py-2 px-4 font-bold">Role</th>
                      <th className="text-left py-2 px-4 font-bold">Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTask?.team?.members?.map((member, index) => (
                      <tr key={index} className="border-t border-gray-100">
                        <td className="py-2 px-4">{index === 0 ? currentTask.team.name : ''}</td>
                        <td className="py-2 px-4">{member.name}</td>
                        <td className="py-2 px-4">{member.role}</td>
                        <td className="py-2 px-4">
                          <div className="flex gap-2">
                            <button className="w-6 h-6 rounded-full bg-[#051A36] flex items-center justify-center">
                              <PhoneIcon className="w-3.5 h-3.5 text-white" />
                            </button>
                            <button className="w-6 h-6 rounded-full bg-[#051A36] flex items-center justify-center">
                              <EnvelopeIcon className="w-3.5 h-3.5 text-white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <RescheduleDateModal
        isOpen={isRescheduleModalOpen}
        onClose={() => setIsRescheduleModalOpen(false)}
        currentTask={currentTask}
      />
    </>
  );
};

const getPriorityColor = (priority) => {
  switch (priority?.toLowerCase()) {
    case 'critical': return 'red-500';
    case 'high': return 'yellow-500';
    case 'medium': return 'green-500';
    case 'low': return 'blue-500';
    default: return 'gray-500';
  }
};

export default TaskBrowser; 