import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconCircleButton from './icon-button';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/json/index.json');
    const data = await response.json();
    const user = data.logins.find(
      (login) => login.username === username && login.password === password
    );

    if (user) {
      onLogin(user.file);
      navigate('/');
    } else {
      setErrorMessage('Invalid credentials');
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setErrorMessage('');
  };

  const backgroundStyle = {
    backgroundImage: "url('/waltero-start-hero.webp')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className="login-container" style={backgroundStyle}>
      <img src="/logotype-waltero.svg" alt="Company Logo" className="logo" />
      <form onSubmit={handleSubmit} className="login-form">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <input
          type="text"
          value={username}
          onChange={handleInputChange(setUsername)}
          placeholder="Username"
          required
        />
        <input
          type="password"
          value={password}
          onChange={handleInputChange(setPassword)}
          placeholder="Password"
          required
        />
        <div className="login-button-container">
            <IconCircleButton
            text="Login"
            onClick={handleSubmit}
            colorScheme="light"
            circlePosition="right"
            />
        </div>
      </form>
    </div>
  );
};

export default Login; 