import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FunnelIcon as FunnelIconOutline } from '@heroicons/react/24/outline';
import { FunnelIcon as FunnelIconSolid } from '@heroicons/react/24/solid';
import {
  setSortConfig,
  setColumnFilter,
  setCheckboxFilter,
} from '../redux/devicesListSlice';
import FilterWindow from './FilterWindow';

const TableHeader = () => {
  const dispatch = useDispatch();
  const sortConfig = useSelector((state) => state.devicesList.sortConfig);
  const devices = useSelector((state) => state.devices.devices);
  const [activeFilter, setActiveFilter] = useState(null);
  const columnFilters = useSelector((state) => state.devicesList.columnFilters);
  const districts = useSelector(state => state.districts.districts);

  const getDistrictName = (districtId) => {
    const district = districts.find(d => d.id === districtId);
    return district ? district.name : 'Unknown District';
  };

  const requestSort = useCallback((key) => {
    dispatch(setSortConfig({ 
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, [dispatch, sortConfig]);

  const handleFilter = useCallback((column, value) => {
    dispatch(setColumnFilter({ column, value }));
  }, [dispatch]);

  const handleCheckboxFilter = useCallback((column, value, checked) => {
    dispatch(setCheckboxFilter({ column, value, checked }));
  }, [dispatch]);

  const getFilterOptions = useCallback((column) => {
    switch (column) {
      case 'district_name':
        return [...new Set(devices.map(d => getDistrictName(d.district)))].filter(Boolean);
      case 'type':
        return [...new Set(devices.map(d => d.type))].filter(Boolean);
      case 'maintenances':
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return [...new Set(devices.flatMap(d => 
          d.maintenances?.filter(m => new Date(m.date) >= today).map(m => m.task) || []
        ))].filter(Boolean);
      case 'status':
        return ['Good', 'Attention', 'Urgent'];
      default:
        return [];
    }
  }, [devices]);

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <span className="inline-block w-[12px] ml-2" />;
    }
    return sortConfig.direction === 'asc' 
      ? <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-b-[8px] border-b-gray-600 translate-y-[-2px]" />
      : <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-t-[8px] border-t-gray-600 translate-y-[-2px]" />;
  };

  const isFilterActive = useCallback((column) => {
    return columnFilters[column] && (
      Array.isArray(columnFilters[column]) 
        ? columnFilters[column].length > 0 
        : columnFilters[column].trim() !== ''
    );
  }, [columnFilters]);

  React.useEffect(() => {
    const handleClickOutside = () => {
      if (activeFilter) {
        setActiveFilter(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [activeFilter]);

  return (
    <thead className="sticky bg-light-gray top-0 z-10">
      <tr>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider sticky top-0">
          <div className="flex items-center">
            <span onClick={() => requestSort('type')}>Type {getSortIcon('type')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer" onClick={() => requestSort('timestamp')}>
          <span>Last Picture {getSortIcon('timestamp')}</span>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer relative">
          <div className="flex items-center">
            <button 
              className="mr-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(activeFilter === 'sensor_id' ? null : 'sensor_id');
              }}
            >
              {isFilterActive('sensor_id') ? (
                <FunnelIconSolid className={`h-4 w-4 text-dark-blue`} />
              ) : (
                <FunnelIconOutline className={`h-4 w-4 ${activeFilter === 'sensor_id' ? 'text-dark-blue' : ''}`} />
              )}
            </button>
            <span onClick={() => requestSort('sensor_id')}>
              Sensor ID {getSortIcon('sensor_id')}
            </span>
          </div>
          {activeFilter === 'sensor_id' && (
            <FilterWindow 
              column="sensor_id"
              options={getFilterOptions('sensor_id')}
              handleFilter={handleFilter}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          )}
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer relative">
          <div className="flex items-center">
            <button 
              className="mr-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(activeFilter === 'district_name' ? null : 'district_name');
              }}
            >
              {isFilterActive('district_name') ? (
                <FunnelIconSolid className={`h-4 w-4 text-dark-blue`} />
              ) : (
                <FunnelIconOutline className={`h-4 w-4 ${activeFilter === 'district_name' ? 'text-dark-blue' : ''}`} />
              )}
            </button>
            <span onClick={() => requestSort('district_name')}>
              District {getSortIcon('district_name')}
            </span>
          </div>
          {activeFilter === 'district_name' && (
            <FilterWindow 
              column="district_name"
              options={getFilterOptions('district_name')}
              handleFilter={handleFilter}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          )}
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer">
          <div className="flex items-center">
            <button 
              className="mr-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(activeFilter === 'type' ? null : 'type');
              }}
            >
              {isFilterActive('type') ? (
                <FunnelIconSolid className={`h-4 w-4 text-dark-blue`} />
              ) : (
                <FunnelIconOutline className={`h-4 w-4 ${activeFilter === 'type' ? 'text-dark-blue' : ''}`} />
              )}
            </button>
            <span onClick={() => requestSort('type')}>
              Tunnel Type {getSortIcon('type')}
            </span>
          </div>
          {activeFilter === 'type' && (
            <FilterWindow 
              column="type"
              options={getFilterOptions('type')}
              handleFilter={handleFilter}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          )}
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer relative">
          <div className="flex items-center">
            <button 
              className="mr-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(activeFilter === 'maintenances' ? null : 'maintenances');
              }}
            >
              {isFilterActive('maintenances') ? (
                <FunnelIconSolid className={`h-4 w-4 text-dark-blue`} />
              ) : (
                <FunnelIconOutline className={`h-4 w-4 ${activeFilter === 'maintenances' ? 'text-dark-blue' : ''}`} />
              )}
            </button>
            <span onClick={() => requestSort('maintenances')}>
              Scheduled Maintenances {getSortIcon('maintenances')}
            </span>
          </div>
          {activeFilter === 'maintenances' && (
            <FilterWindow 
              column="maintenances"
              options={getFilterOptions('maintenances')}
              handleFilter={handleFilter}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          )}
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider cursor-pointer relative">
          <div className="flex items-center">
            <button 
              className="mr-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(activeFilter === 'status' ? null : 'status');
              }}
            >
              {isFilterActive('status') ? (
                <FunnelIconSolid className={`h-4 w-4 text-dark-blue`} />
              ) : (
                <FunnelIconOutline className={`h-4 w-4 ${activeFilter === 'status' ? 'text-dark-blue' : ''}`} />
              )}
            </button>
            <span onClick={() => requestSort('status')}>
              Status {getSortIcon('status')}
            </span>
          </div>
          {activeFilter === 'status' && (
            <FilterWindow 
              column="status"
              options={getFilterOptions('status')}
              handleFilter={handleFilter}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader); 