import devicesDubai from './final_populated_map.json';
import { setDistricts } from '../redux/districtsSlice';
import { setDevices } from '../redux/devicesSlice';
import { setImages } from '../redux/imagesSlice';
import { setLocalDeviceMarkers, setLocalDistrictMarkers } from '../redux/markerMakerSlice';
import { store } from '../redux/store';
import { setMapSettings } from '../redux/settingsSlice';
import { setTasks } from '../redux/taskManagerSlice';

const addMarkerProp = (devices) => {
  return devices.map(device => {
    return {
      ...device,
      marker: null,
    };
  });
};
  
  const devicesUpdated = {
    ...devicesDubai,
    devices: addMarkerProp(devicesDubai.devices)
  };
  
  const dispatchAllData = (data) => {
    // Update main stores
    store.dispatch(setDevices(data.devices));
    store.dispatch(setDistricts(data.districts));
    store.dispatch(setImages(data.images));
    store.dispatch(setTasks(data.tasks));
    if (data.settings) {
      store.dispatch(setMapSettings(data.settings));
    }
    
    // Update local copies
    store.dispatch(setLocalDeviceMarkers(data.devices));
    store.dispatch(setLocalDistrictMarkers(data.districts));
  };

const fetchJsonData = async (fileName) => {
  const response = await fetch(`/json/${fileName}`);
  return response.json();
};

const updateStoreWithJson = async (fileName) => {
  const data = await fetchJsonData(fileName);
  dispatchAllData(data);
};

export const downloadJson = () => {
  const state = store.getState();
  const jsonData = {
    devices: state.devices.devices,
    districts: state.districts.districts,
    images: state.images,
    settings: state.settings,
    tasks: state.taskManager.tasks
  };
  
  const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'map_data.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const uploadJson = async (file) => {
  try {
    const text = await file.text();
    const json = JSON.parse(text);
    dispatchAllData(json);
    return true;
  } catch (error) {
    console.error('Error uploading JSON:', error);
    return false;
  }
};

export default updateStoreWithJson;
