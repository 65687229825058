import React from 'react';
import TableHeader from './TableHeader';
import DevicesTableBody from './DevicesTableBody';

const DevicesList = () => {
  console.log("devices list");
  return (
    <div className="p-0 m-6">
      <div className="overflow-x-auto h-[calc(100vh-6rem)] max-h-[calc(100vh-5rem)] overflow-y-auto border border-gray-200 rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <TableHeader />
          <DevicesTableBody />
        </table>
      </div>
    </div>
  );
};

export default DevicesList; 