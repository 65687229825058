import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSortConfig } from '../redux/taskManagerSlice';

const TaskTableHeader = () => {
  const dispatch = useDispatch();
  const sortConfig = useSelector((state) => state.taskManager.sortConfig);

  const requestSort = (key) => {
    dispatch(setSortConfig({ key }));
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <span className="inline-block w-[12px] ml-2" />;
    }
    return sortConfig.direction === 'asc' 
      ? <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-b-[8px] border-b-gray-600 translate-y-[-2px]" />
      : <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-t-[8px] border-t-gray-600 translate-y-[-2px]" />;
  };

  return (
    <thead className="sticky bg-light-gray top-0 z-10">
      <tr>
        <th scope="col" className="w-12 px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('status')}>Status {getSortIcon('status')}</span>
          </div>
        </th>
        <th scope="col" className="w-24 px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('aiScore')}>AI score {getSortIcon('aiScore')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('object')}>Object {getSortIcon('object')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('initiatedBy')}>Initiated by {getSortIcon('initiatedBy')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('missionType')}>Mission type {getSortIcon('missionType')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('dueDate')}>Due date {getSortIcon('dueDate')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('priority')}>Priority {getSortIcon('priority')}</span>
          </div>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-gray-700 tracking-wider whitespace-nowrap">
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={() => requestSort('responsibleTeam')}>Responsible team {getSortIcon('responsibleTeam')}</span>
          </div>
        </th>
        <th scope="col" className="w-12 px-6 py-3"></th>
      </tr>
    </thead>
  );
};

export default React.memo(TaskTableHeader); 