import { useSelector, useDispatch } from 'react-redux';
import './InspectorPanel.css';
import { useMemo, useEffect } from 'react';
import { updateModifiedProperty, saveChanges, removeMarkers } from '../../redux/markerMakerSlice';
import ImageSelector from './ImageSelector';

const InspectorPanel = () => {
  const dispatch = useDispatch();
  const {
    inspectorVisible,
    selectedMarkerIds,
    currentMarkerType,
    inspectorSaveDisabled,
    localDeviceMarkers,
    localDistrictMarkers,
    modifiedProperties
  } = useSelector(state => state.markerMaker);
  
  const availableImages = useMemo(() => {
    // This should be populated with your actual available images
    return ['/path/to/image1.jpg', '/path/to/image2.jpg']; // Replace with actual images
  }, []);
  
  const selectedMarkers = currentMarkerType === 'device' 
    ? localDeviceMarkers.filter(m => selectedMarkerIds.includes(m.sensor_id))
    : localDistrictMarkers.filter(m => selectedMarkerIds.includes(m.id));

  const markerProperties = useMemo(() => {
    if (selectedMarkers.length === 0) return {};
    
    return selectedMarkers.reduce((acc, marker) => {
      Object.entries(marker).forEach(([key, value]) => {
        if (acc[key] === undefined) {
          if (Array.isArray(value)) {
            acc[key] = JSON.parse(JSON.stringify(value));
          } else {
            acc[key] = value;
          }
        } else if (Array.isArray(value) && Array.isArray(acc[key])) {
          acc[key] = acc[key].map((item, index) => {
            const newItem = { ...item };
            Object.keys(item).forEach(itemKey => {
              if (value[index]?.[itemKey] !== item[itemKey]) {
                newItem[itemKey] = '';
              }
            });
            return newItem;
          });
        } else if (acc[key] !== value) {
          acc[key] = '';
        }
      });
      return acc;
    }, {});
  }, [selectedMarkers]);

  useEffect(() => {
    dispatch(updateModifiedProperty({ property: null, value: null }));
  }, [selectedMarkerIds, dispatch]);

  useEffect(() => {
    if (selectedMarkerIds.length > 0) {
      dispatch(updateModifiedProperty({ property: null, value: null }));
      dispatch({ type: 'markerMaker/setSelectedMarkerIds', payload: [] });
    }
  }, [currentMarkerType, dispatch, selectedMarkerIds.length]);

  if (!inspectorVisible || selectedMarkerIds.length === 0) return null;

  const getPlaceholder = (propertyPath) => {
    const getNestedValue = (obj, path) => {
      const parts = path.split('.');
      return parts.reduce((current, key) => current?.[key], obj);
    };
    
    const values = new Set(selectedMarkers.map(marker => getNestedValue(marker, propertyPath)));
    
    if (values.size === 0) return 'No value';
    if (values.size === 1) {
      const value = values.values().next().value;
      return value || 'No value';
    }
    return 'Multiple values';
  };

  const getDisplayValue = (propertyPath) => {
    // Check if this property has been modified
    const getNestedModifiedValue = (obj, path) => {
      const parts = path.split('.');
      return parts.reduce((current, key) => current?.[key], obj);
    };
    const modifiedValue = getNestedModifiedValue(modifiedProperties, propertyPath);
    if (modifiedValue !== undefined) {
      return modifiedValue;
    }

    // Get the value from markerProperties for nested paths
    const getNestedValue = (obj, path) => {
      const parts = path.split('.');
      return parts.reduce((current, key) => current?.[key], obj);
    };
    const value = getNestedValue(markerProperties, propertyPath);
    
    // Add this check to handle array values properly
    if (value !== '' && value !== undefined) {
      return value;
    }
    
    // If the value is empty, fall back to the placeholder value
    return "";
  };

  const addNewMaintenance = () => {
    const maintenances = [...(selectedMarkers[0]?.maintenances || [])];
    const lastMaintenance = maintenances[maintenances.length - 1] || {
      date: new Date().toISOString().split('T')[0].replace(/-/g, '/'),
      task: '',
      team: ''
    };
    handlePropertyChange('maintenances', [...maintenances, { ...lastMaintenance }]);
  };

  const handlePropertyChange = (property, value) => {
    dispatch(updateModifiedProperty({ property, value }));
  };

  const handleSave = () => {
    dispatch(saveChanges());
  };

  const handleRemove = () => {
    dispatch(removeMarkers());
  };

  return (
    <div className="inspector-panel">
      <div className="inspector-header">
        <h4>{`Devices (${selectedMarkerIds.length})`}</h4>
        <div className="inspector-buttons">
          <button 
            className="save-button"
            disabled={inspectorSaveDisabled}
            onClick={handleSave}
          >
            Save
          </button>
          <button 
            className="remove-button"
            onClick={handleRemove}
          >
            Delete
          </button>
        </div>
      </div>
      
      <div className="inspector-content">
        {currentMarkerType === 'district' ? (
          <>
            <div className="field-row">
              <label htmlFor="markerId">ID:</label>
              <input
                type="text"
                id="markerId"
                value={getDisplayValue('id')}
                placeholder={getPlaceholder('id')}
                onChange={(e) => handlePropertyChange('id', e.target.value)}
              />
            </div>
            
            <div className="field-row">
              <label htmlFor="markerName">Name:</label>
              <input
                type="text"
                id="markerName"
                value={getDisplayValue('name')}
                placeholder={getPlaceholder('name')}
                onChange={(e) => handlePropertyChange('name', e.target.value)}
              />
            </div>

            <div className="field-row">
              <label htmlFor="markerImage">Image:</label>
              <ImageSelector
                currentImage={getDisplayValue('image')}
                placeholder={getPlaceholder('image')}
                onImageSelect={(value) => handlePropertyChange('image', value)}
                markerType={currentMarkerType}
              />
            </div>
          </>
        ) : (
          <>
            <details open>
              <summary>Main Properties</summary>
              <div className="field-row">
                <label htmlFor="sensorId">Sensor ID:</label>
                <span className="read-only-value">
                  {getDisplayValue('sensor_id') || getPlaceholder('sensor_id')}
                </span>
              </div>
              
              <div className="field-row">
                <label htmlFor="status">Status:</label>
                <input
                  type="number"
                  id="status"
                  value={getDisplayValue('status')}
                  placeholder={getPlaceholder('status')}
                  onChange={(e) => handlePropertyChange('status', e.target.value)}
                />
              </div>
              
              <div className="field-row">
                <label htmlFor="battery">Battery (%):</label>
                <input
                  type="number"
                  id="battery"
                  value={getDisplayValue('battery')}
                  placeholder={getPlaceholder('battery')}
                  onChange={(e) => handlePropertyChange('battery', e.target.value)}
                />
              </div>
              
              <div className="field-row">
                <label htmlFor="signal">Signal (%):</label>
                <input
                  type="number"
                  id="signal"
                  value={getDisplayValue('signal')}
                  placeholder={getPlaceholder('signal')}
                  onChange={(e) => handlePropertyChange('signal', e.target.value)}
                />
              </div>
              
              <div className="field-row">
                <label htmlFor="district">District:</label>
                <input
                  type="text"
                  id="district"
                  value={getDisplayValue('district')}
                  placeholder={getPlaceholder('district')}
                  onChange={(e) => handlePropertyChange('district', e.target.value)}
                />
              </div>
              
              <div className="field-row">
                <label htmlFor="location">Location:</label>
                <input
                  type="number"
                  id="location"
                  value={getDisplayValue('location')}
                  placeholder={getPlaceholder('location')}
                  onChange={(e) => handlePropertyChange('location', e.target.value)}
                />
              </div>
              
              <div className="field-row">
                <label htmlFor="type">Type:</label>
                <input
                  type="text"
                  id="type"
                  value={getDisplayValue('type')}
                  placeholder={getPlaceholder('type')}
                  onChange={(e) => handlePropertyChange('type', e.target.value)}
                />
              </div>
            </details>

            <details>
              <summary>Measures</summary>
              {(Array.isArray(markerProperties.measures) ? markerProperties.measures : Array(10).fill({})).map((measure, index) => (
                <div key={index} className="measure-item">
                  <div className="item-header">
                    <h5>Measure {index + 1}</h5>
                  </div>
                  <div className="date-score-row">
                    <div className="field-group">
                      <label>Date:</label>
                      <input
                        type="text"
                        value={getDisplayValue(`measures.${index}.date`)}
                        placeholder={getPlaceholder(`measures.${index}.date`)}
                        onChange={(e) => {
                          const newMeasures = Array(10).fill({}).map((_, i) => ({
                            ...markerProperties.measures?.[i],
                            ...(i === index ? { date: e.target.value } : {})
                          }));
                          handlePropertyChange('measures', newMeasures);
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label>Score:</label>
                      <input
                        type="text"
                        value={getDisplayValue(`measures.${index}.ai_score`)}
                        placeholder={getPlaceholder(`measures.${index}.ai_score`)}
                        onChange={(e) => {
                          const newMeasures = Array(10).fill({}).map((_, i) => ({
                            ...markerProperties.measures?.[i],
                            ...(i === index ? { ai_score: e.target.value } : {})
                          }));
                          handlePropertyChange('measures', newMeasures);
                        }}
                      />
                    </div>
                  </div>
                  <div className="field-row">
                    <label>Image:</label>
                    <ImageSelector
                      currentImage={getDisplayValue(`measures.${index}.image`)}
                      placeholder={getPlaceholder(`measures.${index}.image`)}
                      onImageSelect={(value) => {
                        const newMeasures = Array(10).fill({}).map((_, i) => ({
                          ...markerProperties.measures?.[i],
                          ...(i === index ? { image: value } : {})
                        }));
                        handlePropertyChange('measures', newMeasures);
                      }}
                      markerType={currentMarkerType}
                    />
                  </div>
                  {Array(3).fill({}).map((_, readingIndex) => (
                    <div key={readingIndex} className="reading-row">
                      <div className="reading-inputs">
                        <input
                          type="text"
                          placeholder="Key"
                          value={getDisplayValue(`measures.${index}.readings.${readingIndex}.key`)}
                          onChange={(e) => {
                            const newMeasures = Array(10).fill({}).map((_, i) => ({
                              ...markerProperties.measures?.[i],
                              ...(i === index ? {
                                readings: Array(3).fill({}).map((_, j) => ({
                                  ...markerProperties.measures?.[i]?.readings?.[j],
                                  ...(j === readingIndex ? { key: e.target.value } : {})
                                }))
                              } : {})
                            }));
                            handlePropertyChange('measures', newMeasures);
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Value"
                          value={getDisplayValue(`measures.${index}.readings.${readingIndex}.value`)}
                          onChange={(e) => {
                            const newMeasures = Array(10).fill({}).map((_, i) => ({
                              ...markerProperties.measures?.[i],
                              ...(i === index ? {
                                readings: Array(3).fill({}).map((_, j) => ({
                                  ...markerProperties.measures?.[i]?.readings?.[j],
                                  ...(j === readingIndex ? { value: e.target.value } : {})
                                }))
                              } : {})
                            }));
                            handlePropertyChange('measures', newMeasures);
                          }}
                        />
                        <select
                          value={getDisplayValue(`measures.${index}.readings.${readingIndex}.status`)}
                          onChange={(e) => {
                            const newMeasures = Array(10).fill({}).map((_, i) => ({
                              ...markerProperties.measures?.[i],
                              ...(i === index ? {
                                readings: Array(3).fill({}).map((_, j) => ({
                                  ...markerProperties.measures?.[i]?.readings?.[j],
                                  ...(j === readingIndex ? { status: e.target.value } : {})
                                }))
                              } : {})
                            }));
                            handlePropertyChange('measures', newMeasures);
                          }}
                        >
                          <option value="0">Neutral</option>
                          <option value="1">Good</option>
                          <option value="2">Urgent</option>
                          <option value="3">Attention</option>
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </details>

            <details>
              <summary>Maintenances</summary>
              {(markerProperties.maintenances || []).map((maintenance, index) => (
                <div key={index} className="maintenance-item">
                  <div className="item-header">
                    <h5>Maintenance {index + 1}</h5>
                    <button onClick={() => {
                      const newMaintenances = markerProperties.maintenances.filter((_, i) => i !== index);
                      handlePropertyChange('maintenances', newMaintenances);
                    }}>Remove</button>
                  </div>
                  <div className="field-row">
                    <label>Date:</label>
                    <input
                      type="text"
                      value={maintenance.date}
                      onChange={(e) => {
                        const newMaintenances = [...markerProperties.maintenances];
                        newMaintenances[index] = { ...maintenance, date: e.target.value };
                        handlePropertyChange('maintenances', newMaintenances);
                      }}
                    />
                  </div>
                  <div className="field-row">
                    <label>Task:</label>
                    <input
                      type="text"
                      value={maintenance.task}
                      onChange={(e) => {
                        const newMaintenances = [...markerProperties.maintenances];
                        newMaintenances[index] = { ...maintenance, task: e.target.value };
                        handlePropertyChange('maintenances', newMaintenances);
                      }}
                    />
                  </div>
                  <div className="field-row">
                    <label>Team:</label>
                    <input
                      type="text"
                      value={maintenance.team}
                      onChange={(e) => {
                        const newMaintenances = [...markerProperties.maintenances];
                        newMaintenances[index] = { ...maintenance, team: e.target.value };
                        handlePropertyChange('maintenances', newMaintenances);
                      }}
                    />
                  </div>
                </div>
              ))}
              <button onClick={addNewMaintenance}>Add Maintenance</button>
            </details>
          </>
        )}
      </div>
    </div>
  );
};

export default InspectorPanel; 