import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { rescheduleTask } from '../redux/taskManagerSlice';
import { addNotification } from '../redux/notificationSlice';
import IconCircleButton from '../components/icon-button';

const RescheduleDateModal = ({ isOpen, onClose, currentTask }) => {
  const dispatch = useDispatch();
  const [newDate, setNewDate] = useState(currentTask?.dueDate?.replace(/\//g, '-') || '');

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newDate) {
      dispatch(rescheduleTask({
        taskId: currentTask.id,
        newDate: newDate.replace(/-/g, '/')
      }));
      dispatch(addNotification({
        message: 'Task rescheduled successfully',
        type: 'success'
      }));
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[400px]">
        <div className="flex justify-between items-center mb-0 bg-[#FFFA08] p-6 pb-4 pt-4 rounded-t-lg">
          <h2 className="text-xl font-bold text-[#051A36]">Reschedule Task</h2>
          <button onClick={onClose} className="text-[#051A36] hover:text-gray-700">
            ✕
          </button>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label className="block mb-1 text-sm text-gray-600">
                  New Due Date
                </label>
                <input
                  type="date"
                  className="w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900"
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="flex justify-between gap-4 mt-6">
              <div>
                <IconCircleButton
                  text="Cancel"
                  onClick={onClose}
                  colorScheme="light"
                  circlePosition='left'
                />
              </div>
              <div>
                <IconCircleButton
                  text="Reschedule"
                  onClick={handleSubmit}
                  colorScheme="light"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RescheduleDateModal; 