import { getStatusColor } from "../../utils/status_color";

export const clusterRenderer = {
    render: (cluster, stats, map) => {
        const contentElement = (cluster) => {
            const content = document.createElement('div');
            const count = document.createElement('span');
            const contentContainer = document.createElement('div');

            content.classList.add('cluster-pin');
            count.classList.add('cluster-count');
            contentContainer.classList.add('cluster-pin-content');

            content.appendChild(count);
            contentContainer.appendChild(content);

            count.innerHTML = String(cluster.markers.length);

            // Determine the color based on marker statuses
            let hasStatus3 = false;
            let hasStatus2 = false;

            for (const marker of cluster.markers) {
                if (!marker.element) continue;

                const statusContainer = marker.element.querySelector('.data-status-container');
                const status = statusContainer ? statusContainer.getAttribute('data-status') : null;

                if (status === '3') {
                    hasStatus3 = true;
                    break;
                } else if (status === '2') {
                    hasStatus2 = true;
                }
            }

            if (hasStatus3) {
                count.style.color = getStatusColor(3);
            } else if (hasStatus2) {
                count.style.color = getStatusColor(2);
            }

            return contentContainer;
        };

        return new window.google.maps.marker.AdvancedMarkerElement({ 
            position: cluster.position,
            content: contentElement(cluster)
        });
    }
};