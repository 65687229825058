import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentMarkerType: 'device',
  inspectorVisible: false,
  selectedMarkerIds: [],
  localDeviceMarkers: [],
  localDistrictMarkers: [],
  modifiedProperties: {},
  inspectorSaveDisabled: true,
  pushToEnvironmentDisabled: true
};

const markerMakerSlice = createSlice({
  name: 'markerMaker',
  initialState,
  reducers: {
    setCurrentMarkerType: (state, action) => {
      state.currentMarkerType = action.payload;
      state.selectedMarkerIds = [];
      state.modifiedProperties = {};
      state.markerProperties = {};
      state.inspectorSaveDisabled = true;
    },
    setInspectorVisible: (state, action) => {
      state.inspectorVisible = action.payload;
    },
    setSelection: (state, action) => {
      state.selectedMarkerIds = action.payload;
      state.modifiedProperties = {};
      state.markerProperties = {};
      state.inspectorSaveDisabled = true;
      state.pushToEnvironmentDisabled = true;
    },
    clearSelection: (state) => {
      state.selectedMarkerIds = [];
    },
    toggleSelection: (state, action) => {
      const markerIds = Array.isArray(action.payload) ? action.payload : [action.payload];
      const allSelected = markerIds.every(id => state.selectedMarkerIds.includes(id));
      
      if (allSelected) {
        // Remove all markers only if they're all already selected
        state.selectedMarkerIds = state.selectedMarkerIds.filter(
          id => !markerIds.includes(id)
        );
      } else {
        // Add any markers that aren't already selected
        const newMarkers = markerIds.filter(id => !state.selectedMarkerIds.includes(id));
        state.selectedMarkerIds = [...state.selectedMarkerIds, ...newMarkers];
      }
    },
    setMarkerProperties: (state, action) => {
      state.markerProperties = action.payload;
    },
    updateMarkerProperty: (state, action) => {
      const { property, value } = action.payload;
      state.markerProperties[property] = value;
      state.inspectorSaveDisabled = false;
      state.pushToEnvironmentDisabled = false;
    },
    setInspectorSaveDisabled: (state, action) => {
      state.inspectorSaveDisabled = action.payload;
    },
    setPushToEnvironmentDisabled: (state, action) => {
      state.pushToEnvironmentDisabled = action.payload;
    },
    setLocalDeviceMarkers: (state, action) => {
      state.localDeviceMarkers = action.payload.map(marker => ({
        ...marker,
        measures: marker.measures.map(measure => ({
          date: measure.date || '',
          image: measure.image || '',
          ai_score: measure.ai_score || '',
          readings: measure.readings.map(reading => ({
            key: reading.key || '',
            value: reading.value || '',
            status: reading.status || '0'
          }))
        }))
      }));
    },
    setLocalDistrictMarkers: (state, action) => {
      state.localDistrictMarkers = action.payload;
    },
    updateModifiedProperty: (state, action) => {
      const { property, value } = action.payload;
      
      // Handle the reset case
      if (property === null && value === null) {
        state.modifiedProperties = {};
        return;
      }
      
      if (property === 'lat' || property === 'lng') return;
      
      const markers = state.currentMarkerType === 'device' 
        ? state.localDeviceMarkers 
        : state.localDistrictMarkers;
      
      const selectedMarkers = markers.filter(m => 
        state.selectedMarkerIds.includes(
          state.currentMarkerType === 'device' ? m.sensor_id : m.id
        )
      );

      // Handle nested properties (e.g., "measures.0.date")
      const propertyPath = property.split('.');
      const getNestedValue = (obj, path) => {
        return path.reduce((current, key) => {
          // Handle undefined array elements
          if (Array.isArray(current) && current[key] === undefined) {
            return undefined;
          }
          return current?.[key];
        }, obj);
      };

      // Check if all selected markers have the same value for this property
      const firstValue = getNestedValue(selectedMarkers[0], propertyPath);
      const allHaveSameValue = selectedMarkers.every(m => {
        const currentValue = getNestedValue(m, propertyPath);
        if (Array.isArray(currentValue)) {
          return JSON.stringify(currentValue) === JSON.stringify(firstValue);
        }
        return currentValue === firstValue;
      });

      // Only update if the new value is different from the current shared value
      if (value !== firstValue || !allHaveSameValue) {
        // For nested properties, ensure the path exists in modifiedProperties
        let current = state.modifiedProperties;
        for (let i = 0; i < propertyPath.length - 1; i++) {
          const key = propertyPath[i];
          if (!(key in current)) {
            current[key] = !isNaN(propertyPath[i + 1]) ? [] : {};
          }
          current = current[key];
        }
        current[propertyPath[propertyPath.length - 1]] = value;
        
        state.inspectorSaveDisabled = false;
      }
    },
    saveChanges: (state) => {
      const markers = state.currentMarkerType === 'device' 
        ? state.localDeviceMarkers 
        : state.localDistrictMarkers;

      const applyNestedChanges = (obj, changes) => {
        const result = { ...obj };
        Object.entries(changes).forEach(([key, value]) => {
          if (value && typeof value === 'object') {
            result[key] = applyNestedChanges(obj[key] || {}, value);
          } else {
            result[key] = value;
          }
        });
        return result;
      };

      state.selectedMarkerIds.forEach(id => {
        const markerIndex = markers.findIndex(m => 
          state.currentMarkerType === 'device' ? m.sensor_id === id : m.id === id
        );
        
        if (markerIndex !== -1) {
          markers[markerIndex] = applyNestedChanges(
            markers[markerIndex],
            state.modifiedProperties
          );
        }
      });

      if (state.currentMarkerType === 'device') {
        state.localDeviceMarkers = [...markers];
      } else {
        state.localDistrictMarkers = [...markers];
      }
      
      state.modifiedProperties = {};
      state.inspectorSaveDisabled = true;
      state.pushToEnvironmentDisabled = false;
    },
    removeMarkers: (state, action) => {
      const specificMarker = action.payload; // optional - if not provided, removes all selected markers
      
      if (specificMarker) {
        // Single marker removal (right-click case)
        if (state.currentMarkerType === 'device') {
          state.localDeviceMarkers = state.localDeviceMarkers.filter(
            marker => marker.sensor_id !== specificMarker.sensor_id
          );
        } else {
          state.localDistrictMarkers = state.localDistrictMarkers.filter(
            marker => marker.id !== specificMarker.id
          );
        }
        
        // Remove from selection if it was selected
        const markerId = specificMarker.sensor_id || specificMarker.id;
        state.selectedMarkerIds = state.selectedMarkerIds.filter(id => id !== markerId);
      } else {
        // Bulk removal (Delete key case) - removes all selected markers
        if (state.currentMarkerType === 'device') {
          state.localDeviceMarkers = state.localDeviceMarkers.filter(
            marker => !state.selectedMarkerIds.includes(marker.sensor_id)
          );
        } else {
          state.localDistrictMarkers = state.localDistrictMarkers.filter(
            marker => !state.selectedMarkerIds.includes(marker.id)
          );
        }
        state.selectedMarkerIds = [];
      }
      state.inspectorSaveDisabled = true;
      state.pushToEnvironmentDisabled = false;
    },
    updateMarkerPosition: (state, action) => {
      const { markerId, lat, lng, markerType } = action.payload;
      
      if (markerType === 'device') {
        state.localDeviceMarkers = state.localDeviceMarkers.map(marker => 
          marker.sensor_id === markerId 
            ? { ...marker, lat, lng }
            : marker
        );
      } else {
        state.localDistrictMarkers = state.localDistrictMarkers.map(marker => 
          marker.id === markerId 
            ? { ...marker, lat, lng }
            : marker
        );
      }
      state.pushToEnvironmentDisabled = false;
    },
    addNewMarker: (state, action) => {
      const { lat, lng, markerType } = action.payload;
      
      if (markerType === 'device') {
        // Generate unique sensor_id (8 digits + letter)
        let newSensorId;
        do {
          const randomNum = Math.floor(10000000 + Math.random() * 90000000);
          const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
          newSensorId = `${randomNum}${randomLetter}`;
        } while (state.localDeviceMarkers.some(m => m.sensor_id === newSensorId));

        // Create empty measures array with 10 items
        const emptyMeasures = Array(10).fill(null).map(() => ({
          date: '',
          image: '',
          ai_score: '',
          readings: Array(3).fill(null).map(() => ({
            key: '',
            value: '',
            status: '0'
          }))
        }));

        const newDeviceMarker = {
          lat,
          lng,
          sensor_id: newSensorId,
          status: 1,
          battery: 100,
          signal: 100,
          district: '',
          location: 0,
          type: '',
          measures: emptyMeasures,
          maintenances: []
        };
        state.localDeviceMarkers.push(newDeviceMarker);
      } else {
        // Generate unique district id ('new-X')
        const existingNewIds = state.localDistrictMarkers
          .map(m => m.id)
          .filter(id => id.startsWith('new-'))
          .map(id => parseInt(id.split('-')[1]));
        const nextNum = existingNewIds.length > 0 ? Math.max(...existingNewIds) + 1 : 1;

        const newDistrictMarker = {
          lat,
          lng,
          id: `new-${nextNum}`,
          name: '',
          image: 'default.png'
        };
        state.localDistrictMarkers.push(newDistrictMarker);
      }
      state.inspectorSaveDisabled = false;
      state.pushToEnvironmentDisabled = false;
    },
  }
});

export const {
  setCurrentMarkerType,
  setInspectorVisible,
  setSelection,
  clearSelection,
  toggleSelection,
  setMarkerProperties,
  updateMarkerProperty,
  setInspectorSaveDisabled,
  setPushToEnvironmentDisabled,
  setLocalDeviceMarkers,
  setLocalDistrictMarkers,
  updateModifiedProperty,
  saveChanges,
  removeMarkers,
  updateMarkerPosition,
  addNewMarker,
} = markerMakerSlice.actions;

export default markerMakerSlice.reducer; 