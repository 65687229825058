import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeNotification } from '../redux/notificationSlice';

const Notifications = () => {
  const notifications = useSelector(state => state.notifications.notifications);
  const dispatch = useDispatch();

  return (
    <div className="fixed bottom-4 right-4 z-50 flex flex-col-reverse gap-2">
      {notifications.map(notification => (
        <Notification 
          key={notification.id} 
          notification={notification} 
          onRemove={() => dispatch(removeNotification(notification.id))} 
        />
      ))}
    </div>
  );
};

const Notification = ({ notification, onRemove }) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
      setTimeout(onRemove, 500);
    }, notification.duration);

    return () => clearTimeout(timer);
  }, [notification.duration, onRemove]);

  const bgColor = notification.type === 'success' ? 'bg-green-500' : 'bg-red-500';

  return (
    <div 
      className={`${bgColor} text-white px-4 py-2 rounded-lg shadow-lg min-w-[200px] 
        transform transition-all duration-500 ease-in-out
        ${isExiting ? 'opacity-0 translate-x-full' : 'opacity-100'}`}
    >
      <div className="flex justify-between items-center">
        <span>{notification.message}</span>
        <button 
          onClick={() => {
            setIsExiting(true);
            setTimeout(onRemove, 500);
          }}
          className="ml-2 hover:text-gray-200 focus:outline-none"
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default Notifications; 