// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import devicesReducer from './devicesSlice'; // Reducer for handling devices
import districtsReducer from './districtsSlice'; // Reducer for handling districts
import markerMakerReducer from './markerMakerSlice'; // Reducer for handling marker maker state
import mapStateReducer from './mapStateSlice'; // Reducer for handling map state
import imagesReducer from './imagesSlice';
import devicesListSlice from './devicesListSlice';
import settingsReducer from './settingsSlice';
import taskManagerReducer from './taskManagerSlice';
import notificationReducer from './notificationSlice';
export const store = configureStore({
  reducer: {
    devices: devicesReducer,
    districts: districtsReducer,
    mapState: mapStateReducer,
    markerMaker: markerMakerReducer,
    images: imagesReducer,
    devicesList: devicesListSlice,
    settings: settingsReducer,
    taskManager: taskManagerReducer,
    notifications: notificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['markerMaker/setLocalDeviceMarkers', 'markerMaker/setLocalDistrictMarkers'],
      },
    }),
});

export default store;
