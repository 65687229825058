import React from 'react';
import { useSelector } from 'react-redux';
import { selectFilteredAndSortedTasks } from '../redux/taskManagerSlice';

const TaskTableBody = () => {
  const sortedAndFilteredData = useSelector(selectFilteredAndSortedTasks);

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return (
          <span className="relative inline-block w-8 h-8">
            <span className="absolute inset-0 rounded-full bg-green-500"></span>
            <svg className="absolute inset-1.5 w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          </span>
        );
      case 'pending':
        return (
          <span className="relative inline-block w-8 h-8">
            <span className="absolute inset-0 rounded-full bg-status-warning"></span>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <svg className="w-3 h-3 text-white rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" d="M9 5l7 7-7 7" />
              </svg>
              <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </span>
        );
      case 'error':
        return (
          <span className="relative inline-block w-8 h-8">
            <span className="absolute inset-0 rounded-full bg-red-500"></span>
            <svg className="absolute inset-1.5 w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </span>
        );
      default:
        return (
          <span className="inline-block w-8 h-8 rounded-full bg-gray-500"></span>
        );
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'critical':
        return 'text-red-500';
      case 'high':
        return 'text-yellow-500';
      case 'medium':
        return 'text-green-500';
      case 'low':
        return 'text-blue-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {sortedAndFilteredData.map((task) => (
        <tr key={task.id} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              {getStatusIcon(task.status)}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
            {task.aiScore}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500 max-w-[300px]">
            <div className="truncate">
              {task.object}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
            {task.initiatedBy}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
            {task.missionType}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
            {task.dueDate}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <span className={`text-base ${getPriorityColor(task.priority)}`}>
              {task.priority}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
            {task.responsibleTeam}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
            <button className="text-gray-400 hover:text-gray-500">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default React.memo(TaskTableBody); 