import React, { useMemo, useState } from 'react';
import MaintenanceInfo from './MaintenanceInfo';
import IconCircleButton from '../../components/icon-button';
import { useDispatch, useSelector } from 'react-redux';
import { setInspectorViewState, setInspectedDevice } from '../../redux/mapStateSlice';
import IconCircleBox from '../../components/icon-box';
import { CityWideIcon } from '../../components/icons';
import RegularBox from '../../components/regular-box';
import { getStatusColor } from '../../utils/status_color'
import AIHistoryChart from './ai_history_chart';
import ImageBox from '../image_card/ImageBox';
import { scheduleMaintenance } from '../../redux/devicesSlice';
import { addNotification } from '../../redux/notificationSlice';
import { addActivity } from '../../redux/taskManagerSlice';


function DeviceInformation({ className, device: directDevice, showCloseButton = true, logTaskActivity = false }) {
    const dispatch = useDispatch();
    const [maintenanceDate, setMaintenanceDate] = useState(new Date().toISOString().split('T')[0]);
    const [maintenanceTask, setMaintenanceTask] = useState('Clear debris');
    const [maintenanceTeam, setMaintenanceTeam] = useState('Team #12');

    const deviceId = useSelector((state) => state.mapState.inspectedDeviceId);
    const inspectorViewState = useSelector((state) => state.mapState.inspectorViewState);
    const devices = useSelector((state) => state.devices.devices);
    const districts = useSelector((state) => state.districts.districts);
  
    const storeDevice = useMemo(() => 
        devices.find(device => device.sensor_id === deviceId), 
        [deviceId, devices] 
    );
    
    const device = directDevice || storeDevice;
  
    if (!device) {
      return null;
    }

    const closeInspector = () =>
        {
            dispatch(setInspectorViewState('info'));
            dispatch(setInspectedDevice(null));
        }

    // Helper function to get the last maintenance (excluding today)
    const getLastMaintenance = (maintenances) => {
        if (!maintenances || maintenances.length === 0) return null;
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const pastMaintenances = maintenances
            .filter(m => {
                const maintenanceDate = new Date(m.date);
                maintenanceDate.setHours(0, 0, 0, 0);
                return maintenanceDate < today;
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date));
        
        return pastMaintenances[0] || null;
    };

    // Replace the existing lastMaintenance calculation with this:
    const lastMaintenance = getLastMaintenance(device.maintenances);

    // Helper function to format date to YYYY/MM/DD
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
    };

    const getNextMaintenance = (maintenances) => {
        if (!maintenances || maintenances.length === 0) return null;
        
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison
        
        const futureMaintenance = maintenances
            .filter(m => {
                const maintenanceDate = new Date(m.date);
                maintenanceDate.setHours(0, 0, 0, 0);
                return maintenanceDate >= today;
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date));
        
        return futureMaintenance[0] || null;
    };

    const getDistrictName = (districtId) => {
        const district = districts.find(d => d.id === districtId);
        return district ? district.name : 'Unknown District';
    };

    return (
        <div className="w-full">
        <section className={`flex flex-col shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-2xl ${className}`}>
            <div className="flex gap-4 px-5 py-4 bg-white rounded-lg w-full shadow-[0px_0px_10px_rgba(0,0,0,0.1)] rounded-2xl">
                {showCloseButton && (
                    <img 
                        onClick={() => closeInspector()} 
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3aef58863279e7b7cf52b5d4e2d29e91c93502eb63c413617e7cb304032a0bc3?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" 
                        alt="" 
                        className="object-contain shrink-0 self-start w-8 aspect-square" 
                    />
                )}
                
                <div className="h-[360px]">
                    {device && <ImageBox device={device} />}
                </div>
                
                <div id="device-status-info" className="flex flex-col flex-1 min-w-0 text-base leading-tight max-md:max-w-full bg-white">
                    <div className="flex gap-3 text-black">
                        <IconCircleBox
                            text={getDistrictName(device.district) + " #" + device.location}
                            colorScheme="light"
                            circlePosition="left"
                            icon={() => <CityWideIcon />}
                        />
                        <RegularBox
                            colorScheme="light"
                            text={"Sensor ID: " + device.sensor_id}
                        />
                        <IconCircleBox
                            text={"Battery: " + device.battery + "%"}
                            colorScheme="light"
                            circlePosition="left"
                            circleColor={getStatusColor(
                                device.battery < 10 ? 3 : device.battery < 20 ? 2 : 1
                            )}
                        />
                        <IconCircleBox
                            text={"Signal: " + device.signal + "%"}
                            colorScheme="light"
                            circlePosition="left"
                            circleColor={getStatusColor(
                                device.signal < 50 ? 3 : device.signal < 75 ? 2 : 1
                            )}
                        />
                    </div>
                    {inspectorViewState === 'info' && (
                        <div>
                            <hr className="shrink-0 mt-2 max-w-full h-px border border-solid border-slate-300" />
                            <MaintenanceInfo 
                                label="Last maintenance" 
                                text={lastMaintenance ? `${lastMaintenance.date} - ${lastMaintenance.task} - ${lastMaintenance.team}` : "None"} 
                                status={1} 
                            />
                            <MaintenanceInfo 
                                label="Next maintenance" 
                                text={getNextMaintenance(device.maintenances) ? 
                                    `${getNextMaintenance(device.maintenances).date} - ${getNextMaintenance(device.maintenances).task} - ${getNextMaintenance(device.maintenances).team}` 
                                    : "None"} 
                                status={1} 
                            />
                            <AIHistoryChart measurements={device.measures || []} />
                            <div className="flex gap-4 mt-4 font-bold text-center text-stone-900">
                                <IconCircleButton 
                                    text="Maintenance" 
                                    icon={null} 
                                    colorScheme="light" 
                                    onClick={() => dispatch(setInspectorViewState('maintenance-list'))} 
                                    fontSize = '1rem'
                                />
                                <IconCircleButton 
                                    text="Set Reminder" 
                                    icon={null} 
                                    colorScheme="light" 
                                    onClick={() => {/* Add your click handler here */}} 
                                    fontSize = '1rem'
                                />
                                <IconCircleButton 
                                    text="Mark as Clear" 
                                    icon={null} 
                                    colorScheme="light" 
                                    onClick={() => {/* Add your click handler here */}} 
                                    fontSize = '1rem'
                                />
                            </div>
                        </div>
                    )}
                    {inspectorViewState === 'maintenance-list' && (
                        <div>
                            <div className="h-[230px] mt-4">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                ID
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Task
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Team
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {device.maintenances && device.maintenances.map((maintenance, index) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {maintenance.date}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {maintenance.task}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {maintenance.team}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex gap-4 mt-4 font-bold text-center text-stone-900">
                                <IconCircleButton 
                                    text="Dashboard" 
                                    icon={null} 
                                    circlePosition="left"
                                    colorScheme="light" 
                                    onClick={() => dispatch(setInspectorViewState('info'))} 
                                    fontSize = '1rem'
                                />
                                <IconCircleButton 
                                    text="Schedule maintenance" 
                                    icon={null} 
                                    colorScheme="light" 
                                    onClick={() => dispatch(setInspectorViewState('schedule-maintenance'))} 
                                    fontSize = '1rem'
                                />
                            </div>
                        </div>
                    )}
                    {inspectorViewState === 'schedule-maintenance' && (
                        <div>
                            <div className="h-[230px] mt-4">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                ID
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Task
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Team
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <span>-</span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input 
                                                    type="date" 
                                                    value={maintenanceDate}
                                                    onChange={(e) => setMaintenanceDate(e.target.value)}
                                                    className="w-full px-2 py-1 border border-gray-300 rounded" 
                                                />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <select 
                                                    value={maintenanceTask}
                                                    onChange={(e) => setMaintenanceTask(e.target.value)}
                                                    className="w-full px-2 py-1 border border-gray-300 rounded"
                                                >
                                                    <option value="Clear debris">Clear debris</option>
                                                    <option value="Inspect equipment">Inspect</option>
                                                    <option value="Unclog drain">Unclog drain</option>
                                                    <option value="Repair equipment">Repair</option>
                                                    <option value="Seal leaks">Seal leaks</option>
                                                </select>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <select 
                                                    value={maintenanceTeam}
                                                    onChange={(e) => setMaintenanceTeam(e.target.value)}
                                                    className="w-full px-2 py-1 border border-gray-300 rounded"
                                                >
                                                    <option value="Team #12">Team #12</option>
                                                    <option value="Team #13">Team #13</option>
                                                    <option value="Team #21">Team #21</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex gap-4 mt-4 font-bold text-center text-stone-900">
                                <IconCircleButton 
                                    text="Return" 
                                    icon={null} 
                                    circlePosition="left"
                                    colorScheme="light" 
                                    onClick={() => dispatch(setInspectorViewState('maintenance-list'))} 
                                    fontSize = '1rem'
                                />
                                <IconCircleButton 
                                    text="Confirm" 
                                    icon={null} 
                                    colorScheme="light" 
                                    onClick={() => {
                                        const newMaintenance = {
                                            date: formatDate(maintenanceDate),
                                            task: maintenanceTask,
                                            team: maintenanceTeam
                                        };
                                        
                                        // Schedule the maintenance
                                        dispatch(scheduleMaintenance({
                                            deviceId: device.sensor_id,
                                            maintenance: newMaintenance
                                        }));

                                        // Only log activity if explicitly requested
                                        if (logTaskActivity) {
                                            dispatch(addActivity({
                                                description: `Scheduled maintenance: ${maintenanceTask}`,
                                                date: formatDate(maintenanceDate),
                                                status: 'pending',
                                                deviceId: device.sensor_id
                                            }));
                                        }

                                        dispatch(addNotification({
                                            message: 'Maintenance scheduled successfully',
                                            type: 'success'
                                        }));
                                        dispatch(setInspectorViewState('info'));
                                    }} 
                                    fontSize = '1rem'
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
        </div>

    );
}

export default DeviceInformation;