import React, { useState } from 'react';
import TaskManager from './TaskManager';
import TaskBrowser from './TaskBrowser';

const TaskManagerWrapper = () => {
  const [activeView, setActiveView] = useState('manager'); // 'manager' or 'browser'

  return (
    <div className="p-0 m-6">
      {activeView === 'manager' ? (
        <TaskManager onStartTasks={() => setActiveView('browser')} />
      ) : (
        <TaskBrowser onBack={() => setActiveView('manager')} />
      )}
    </div>
  );
};

export default TaskManagerWrapper; 