import React, { useEffect } from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import { ClusteredDeviceMarkers } from './clustered_device_markers';
import { DistrictMarkers } from './district_markers';
import OverlayParent from './overlays_parent';
import { useDispatch, useSelector } from 'react-redux';
import { displayDevices } from '../../redux/mapStateSlice';

const center = { lat: 25.077174, lng: 55.190888 };

// New child component that handles map operations
const MapContent = ({ zoomedDevices, displayedDevices }) => {
  const map = useMap();

  useEffect(() => {
    const devicesToZoom = zoomedDevices?.length > 0 ? zoomedDevices : displayedDevices;
    
    if (devicesToZoom?.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      devicesToZoom.forEach((device) => {
        bounds.extend({ lat: device.lat, lng: device.lng });
      });
      map.fitBounds(bounds, { top: 15, bottom: 15, left: 15, right: 15 });
    }
  }, [zoomedDevices, displayedDevices, map]);

  return (
    <>
      <ClusteredDeviceMarkers/>
      <DistrictMarkers />
    </>
  );
};

const MultiMap = () => {
  const dispatch = useDispatch();
  const deviceData = useSelector((state) => state.devices.devices);
  const zoomedDevices = useSelector((state) => state.mapState.zoomedDevices);
  const displayedDevices = useSelector((state) => state.mapState.displayedDevices);
  const mapSettings = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(displayDevices(deviceData));
  }, [deviceData]);

  return (
    <APIProvider apiKey={"AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY"} version="beta">
      <div className="relative h-full w-full">
        <Map
          defaultZoom={mapSettings.zoom}
          defaultCenter={mapSettings.center}
          mapTypeControlOptions={{
            position: 20,
            style: 1,
          }}
          options={{
            disableDefaultUI: false,
            zoomControl: true,
            streetViewControl: true,
            fullscreenControl: false,
            mapId: mapSettings.mapId,
          }}
          gestureHandling={'greedy'}
          className="w-full h-full"
        >        
          <MapContent 
            zoomedDevices={zoomedDevices} 
            displayedDevices={displayedDevices} 
          />
        </Map>
        <OverlayParent/>
      </div>
    </APIProvider>
  );
};

export default MultiMap;
