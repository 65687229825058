import React from 'react';
import './marker_pin_custom.css';

const MapPin = ({ pinColor = '#051A36', glyphColor = '#FFFFFF', borderColor = '#051A36' }) => {
  return (
    <div
      className="pin-wrapper"
      style={{
        '--pin-color': pinColor,
        '--border-color': borderColor,
        '--glyph-color': glyphColor,
      }}
    >
      <div className="pin1"></div>
    </div>
  );
};

export default MapPin;

