import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapPinIcon } from '@heroicons/react/24/solid';
import IconCircleButton from '../components/icon-button';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { ClusteredDeviceMarkers } from '../interactive_map/new_map/clustered_device_markers';
import { displayDevices } from '../redux/mapStateSlice';
import { addTask } from '../redux/taskManagerSlice';
import { addNotification } from '../redux/notificationSlice';

const CreateTaskModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const devices = useSelector(state => state.devices.devices);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [formData, setFormData] = useState({
    object: '',
    missionType: '',
    district: '',
    device: '',
    responsibleTeam: '',
    dueDate: '',
    dueTime: '',
    priority: 'None',
    reminder: '',
    note: ''
  });
  const [showMap, setShowMap] = useState(false);
  const mapSettings = useSelector((state) => state.settings);
  const selectedDeviceId = useSelector(state => state.mapState.selectedDeviceId);
  const [errors, setErrors] = useState({});
  const districts = useSelector(state => state.districts.districts);

  React.useEffect(() => {
    if (selectedDeviceId && showMap) {
      setFormData(prev => ({ ...prev, device: selectedDeviceId }));
      if (errors.device) {
        setErrors(prev => ({ ...prev, device: null }));
      }
      setShowMap(false);
    }
  }, [selectedDeviceId]);

  React.useEffect(() => {
    if (isOpen) {
      setFormData({
        object: '',
        missionType: '',
        district: '',
        device: '',
        responsibleTeam: '',
        dueDate: '',
        dueTime: '',
        priority: 'None',
        reminder: '',
        note: ''
      });
      setErrors({});
      setShowMap(false);
      setShowSuggestions(false);
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check required fields
    const requiredFields = {
      object: 'Object',
      missionType: 'Mission type',
      district: 'District',
      device: 'Select device',
      responsibleTeam: 'Responsible team',
      dueDate: 'Due date',
      priority: 'Priority'
    };

    const newErrors = {};
    Object.keys(requiredFields).forEach(field => {
      if (!formData[field] || formData[field] === 'None') {
        newErrors[field] = `${requiredFields[field]} is required`;
      }
    });

    setErrors(newErrors);

    // Only proceed if there are no errors
    if (Object.keys(newErrors).length === 0) {
      dispatch(addTask({
        ...formData,
        device_id: formData.device,
        dueDate: formData.dueDate.replace(/-/g, '/')
      }));
      dispatch(addNotification({
        message: 'Task created successfully',
        type: 'success'
      }));
      onClose();
    }
  };

  // Get filtered suggestions based on input
  const filteredDevices = devices.filter(device => 
    device.sensor_id.toLowerCase().includes(formData.device.toLowerCase())
  );

  const handleDeviceSelect = (sensorId) => {
    setFormData({ ...formData, device: sensorId });
    setShowSuggestions(false);
  };

  const handleShowMap = () => {
    setShowMap(true);
    // Display all devices on the map when showing it
    dispatch(displayDevices(devices));
  };

  const handleClose = () => {
    setFormData({
      object: '',
      missionType: '',
      district: '',
      device: '',
      responsibleTeam: '',
      dueDate: '',
      dueTime: '',
      priority: 'None',
      reminder: '',
      note: ''
    });
    setErrors({});
    setShowMap(false);
    setShowSuggestions(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[500px]">
        <div className="flex justify-between items-center mb-0 bg-[#FFFA08] p-6 pb-4 pt-4 rounded-t-lg">
          <h2 className="text-xl font-bold text-[#051A36]">Create Task</h2>
          <button onClick={handleClose} className="text-[#051A36] hover:text-gray-700">
            ✕
          </button>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label className={`block mb-1 text-sm ${errors.object ? 'text-red-500' : 'text-gray-600'}`}>
                  Object *
                </label>
                <input
                  type="text"
                  placeholder="Set object"
                  className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 ${
                    errors.object ? 'border-red-500' : ''
                  }`}
                  value={formData.object}
                  onChange={(e) => {
                    setFormData({...formData, object: e.target.value});
                    if (errors.object) {
                      setErrors({...errors, object: null});
                    }
                  }}
                />
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <label className={`block mb-1 text-sm ${errors.missionType ? 'text-red-500' : 'text-gray-600'}`}>
                    Mission type *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter mission type"
                    className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 ${
                      errors.missionType ? 'border-red-500' : ''
                    }`}
                    value={formData.missionType}
                    onChange={(e) => {
                      setFormData({...formData, missionType: e.target.value});
                      if (errors.missionType) {
                        setErrors({...errors, missionType: null});
                      }
                    }}
                  />
                </div>
                <div className="flex-1">
                  <label className={`block mb-1 text-sm ${errors.priority ? 'text-red-500' : 'text-gray-600'}`}>
                    Priority *
                  </label>
                  <select
                    className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 h-[42px] ${
                      errors.priority ? 'border-red-500' : ''
                    }`}
                    value={formData.priority}
                    onChange={(e) => {
                      setFormData({...formData, priority: e.target.value});
                      if (errors.priority) {
                        setErrors({...errors, priority: null});
                      }
                    }}
                  >
                    <option value="None">Select priority</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                    <option value="Critical">Critical</option>
                  </select>
                </div>
              </div>

              <div>
                <label className={`block mb-1 text-sm ${errors.district ? 'text-red-500' : 'text-gray-600'}`}>
                  District *
                </label>
                <select
                  className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 h-[42px] ${
                    errors.district ? 'border-red-500' : ''
                  }`}
                  value={formData.district}
                  onChange={(e) => {
                    setFormData({...formData, district: e.target.value});
                    if (errors.district) {
                      setErrors({...errors, district: null});
                    }
                  }}
                >
                  <option value="">Select district</option>
                  {districts.map(district => (
                    <option key={district.name} value={district.name}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="relative">
                <label className={`block mb-1 text-sm ${errors.device ? 'text-red-500' : 'text-gray-600'}`}>
                  Select device *
                </label>
                {!showMap && (
                  <div className="flex gap-2">
                    <input
                      type="text"
                      placeholder="Select device"
                      className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 ${
                        errors.device ? 'border-red-500' : ''
                      }`}
                      value={formData.device}
                      onChange={(e) => {
                        setFormData({...formData, device: e.target.value});
                        setShowSuggestions(true);
                        if (errors.device) {
                          setErrors({...errors, device: null});
                        }
                      }}
                      onFocus={() => setShowSuggestions(true)}
                    />
                    <button
                      type="button"
                      className="p-2 border rounded hover:bg-gray-50"
                      onClick={handleShowMap}
                    >
                      <MapPinIcon className="w-6 h-6 text-gray-400" />
                    </button>
                  </div>
                )}
                
                {!showMap && showSuggestions && formData.device && (
                  <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
                    {filteredDevices.map((device) => (
                      <div
                        key={device.sensor_id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleDeviceSelect(device.sensor_id)}
                      >
                        {device.sensor_id}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {showMap ? (
                <div className="relative h-[400px] mt-4 rounded-lg overflow-hidden">
                  <button
                    onClick={() => setShowMap(false)}
                    className="absolute right-2 top-2 z-10 bg-white rounded-full w-8 h-8 flex items-center justify-center shadow-md hover:bg-gray-100"
                  >
                    ✕
                  </button>
                  <APIProvider apiKey={"AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY"}>
                    <Map
                      defaultZoom={mapSettings.zoom}
                      defaultCenter={mapSettings.center}
                      mapTypeControlOptions={{
                        position: 20,
                        style: 1,
                      }}
                      options={{
                        disableDefaultUI: false,
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        mapId: mapSettings.mapId,
                      }}
                      gestureHandling={'greedy'}
                      className="w-full h-full"
                    >
                      <ClusteredDeviceMarkers 
                        onDeviceSelect={(device) => {
                          setFormData({ ...formData, device: device.sensor_id });
                          if (errors.device) {
                            setErrors(prev => ({ ...prev, device: null }));
                          }
                          setShowMap(false);
                        }}
                      />
                    </Map>
                  </APIProvider>
                </div>
              ) : (
                <>
                  <div>
                    <label className={`block mb-1 text-sm ${errors.responsibleTeam ? 'text-red-500' : 'text-gray-600'}`}>
                      Responsible team *
                    </label>
                    <select
                      className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 h-[42px] ${
                        errors.responsibleTeam ? 'border-red-500' : ''
                      }`}
                      value={formData.responsibleTeam}
                      onChange={(e) => {
                        setFormData({...formData, responsibleTeam: e.target.value});
                        if (errors.responsibleTeam) {
                          setErrors({...errors, responsibleTeam: null});
                        }
                      }}
                    >
                      <option value="">Select team</option>
                      <option value="12">Team #12</option>
                      <option value="22">Team #22</option>
                      <option value="23">Team #23</option>
                      <option value="25">Team #25</option>
                    </select>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label className={`block mb-1 text-sm ${errors.dueDate ? 'text-red-500' : 'text-gray-600'}`}>
                        Due date *
                      </label>
                      <input
                        type="date"
                        className={`w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 ${
                          errors.dueDate ? 'border-red-500' : ''
                        }`}
                        value={formData.dueDate}
                        onChange={(e) => {
                          setFormData({...formData, dueDate: e.target.value});
                          if (errors.dueDate) {
                            setErrors({...errors, dueDate: null});
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <label className="block mb-1 text-sm text-gray-600">Due time</label>
                      <input
                        type="time"
                        className="w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900"
                        value={formData.dueTime}
                        onChange={(e) => setFormData({...formData, dueTime: e.target.value})}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block mb-1 text-sm text-gray-600">Reminder</label>
                    <select
                      className="w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900 h-[42px]"
                      value={formData.reminder}
                      onChange={(e) => setFormData({...formData, reminder: e.target.value})}
                    >
                      <option value="">Set reminder</option>
                      <option value="1day">1 business day before</option>
                      <option value="2days">2 business days before</option>
                      <option value="3days">3 business days before</option>
                    </select>
                  </div>

                  <div>
                    <label className="block mb-1 text-sm text-gray-600">Note</label>
                    <textarea
                      placeholder="Add note"
                      className="w-full p-2 border rounded selection:bg-blue-200 selection:text-blue-900"
                      value={formData.note}
                      onChange={(e) => setFormData({...formData, note: e.target.value})}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-between gap-4 mt-6">
              <div className="flex gap-4">
                <div>
                  <IconCircleButton
                    text="Cancel"
                    onClick={handleClose}
                    colorScheme="light"
                    circlePosition='left'
                  />
                </div>
                <div>
                  <IconCircleButton
                    text="Clear"
                    onClick={(e) => {
                      e.preventDefault();
                      setFormData({
                        object: '',
                        missionType: '',
                        district: '',
                        device: '',
                        responsibleTeam: '',
                        dueDate: '',
                        dueTime: '',
                        priority: 'None',
                        reminder: '',
                        note: ''
                      });
                    }}
                    colorScheme="light"
                    circlePosition='left'
                  />
                </div>
              </div>
              <div>
                <IconCircleButton
                  text="Create"
                  onClick={handleSubmit}
                  colorScheme="light"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTaskModal; 