/* global google */
import React, { useEffect, useMemo, useState } from 'react';
import { APIProvider, Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useSelector } from 'react-redux';
import './Insights.css';

const HeatmapLayer = ({ data, measureIndex }) => {
  const map = useMap();
  const visualization = useMapsLibrary('visualization');

  const heatmap = useMemo(() => {
    if (!visualization || typeof google === 'undefined') return null;

    return new google.maps.visualization.HeatmapLayer();
  }, [visualization]);

  useEffect(() => {
    if (!heatmap || !map || typeof google === 'undefined') return;

    // Group data by location and calculate average AI score
    const locationMap = data.reduce((acc, device) => {
      const measure = device.measures[measureIndex];
      const aiScore = parseInt(measure.ai_score, 10);
      const locationKey = `${device.lat},${device.lng}`;

      if (!acc[locationKey]) {
        acc[locationKey] = { totalScore: 0, count: 0, location: new google.maps.LatLng(device.lat, device.lng) };
      }

      acc[locationKey].totalScore += aiScore;
      acc[locationKey].count += 1;

      return acc;
    }, {});

    // Calculate average AI scores
    const heatmapData = Object.values(locationMap).map(({ totalScore, count, location }) => ({
      location,
      weight: totalScore / count, // Calculate average AI score
    }));

    // Normalize weights based on AI score
    const maxWeight = Math.max(...heatmapData.map(point => point.weight));
    const minWeight = Math.min(...heatmapData.map(point => point.weight));
    const threshold = 1; // Start showing green above this value

    const normalizedHeatmapData = heatmapData.map(point => ({
      ...point,
      weight: point.weight > threshold ? ((point.weight - minWeight) / (maxWeight - minWeight) * 100) : 10 // Normalize to 0-100
    }));

    heatmap.setData(normalizedHeatmapData);

    heatmap.setOptions({
      gradient: [
        'rgba(0, 0, 0, 0)',    // Transparent
        'rgba(0, 255, 0, 0.5)', // Green
        'rgba(0, 255, 0, 1)',
        'rgba(255, 255, 0, 1)', // Yellow
        'rgba(255, 0, 0, 1)'   // Red
      ],
      maxIntensity: 100,
      radius: 40, // Adjust this value to make blobs wider
    });

    heatmap.setMap(map);

    return () => {
      heatmap.setMap(null);
    };
  }, [heatmap, map, data, measureIndex]);

  return null;
};

const Insights = () => {
  const deviceData = useSelector((state) => state.devices.devices);
  const mapSettings = useSelector((state) => state.settings);
  const [measureIndex, setMeasureIndex] = useState(0);

  // Assuming all devices have the same measure dates, use the first device's measures
  const measureDates = deviceData[0]?.measures.map(measure => measure.date) || [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    fontSize: '0.8rem',
    padding: '0.7rem 1rem',
    width: '100px',
    borderRadius: '10px',
    borderWidth: '2px',
    borderColor: 'white',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    backgroundColor: '#051A36',
    color: 'white',
    margin: '2px',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#123B7A',
    borderColor: 'yellow',
  };

  const hoverButtonStyle = {
    ...buttonStyle,
    borderColor: 'yellow',
  };

  return (
    <APIProvider
      apiKey={"AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY"}
      libraries={['visualization']}
    >
      <div className="insights-container">
        <div className="measure-buttons" style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Transparent background
          padding: '10px',
          borderRadius: '10px', // Rounder edges for the container
          boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
          zIndex: 1000
        }}>
          <h3 style={{
            ...buttonStyle,
            cursor: 'default', // Indicate it's not clickable
            pointerEvents: 'none', // Disable click events
            margin: '0 0 10px 0',
            border: 'none', // Make borderless
          }}>
            Area Health
          </h3>
          {measureDates.map((date, index) => (
            <button
              key={index}
              onClick={() => setMeasureIndex(index)}
              style={index === measureIndex ? activeButtonStyle : buttonStyle}
              onMouseEnter={(e) => e.currentTarget.style.borderColor = 'yellow'}
              onMouseLeave={(e) => e.currentTarget.style.borderColor = index === measureIndex ? 'yellow' : 'white'}
            >
              {formatDate(date)}
            </button>
          ))}
        </div>
        <Map
          defaultZoom={mapSettings.zoom}
          defaultCenter={mapSettings.center}
          mapTypeControlOptions={{
            position: 20,
            style: 1,
          }}
          options={{
            disableDefaultUI: false,
            zoomControl: true,
            streetViewControl: true,
            fullscreenControl: false,
            mapId: mapSettings.mapId,
          }}
          gestureHandling={'greedy'}
          className="w-full h-full"
        >
          <HeatmapLayer data={deviceData} measureIndex={measureIndex} />
        </Map>
      </div>
    </APIProvider>
  );
};

export default Insights;
