import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import MultiMap from './interactive_map/new_map/map_parent';
import RegularButton from './components/regular-button';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import updateStoreWithJson from './json_data/import_to_store';
import MarkerMaker from './marker_maker/MarkerMaker';
import DevicesList from './device_list/DevicesList';
import Notifications from './components/Notifications';
import TaskManagerWrapper from './task_manager/TaskManagerWrapper';
import Insights from './insights/Insights';
import Login from './components/Login';
import { useNavigate, useLocation } from 'react-router-dom';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [jsonFile, setJsonFile] = useState('');

  const handleLogin = (file) => {
    setJsonFile(file);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      updateStoreWithJson(jsonFile);
    }
  }, [isAuthenticated, jsonFile]);

  return (
    <Provider store={store}>
      <Router>
        {!isAuthenticated ? (
          <Login onLogin={handleLogin} />
        ) : (
          <div className="flex h-screen">
            <LeftNav />
            <div className="flex flex-col flex-grow">
              <TopBar />
              <div className="flex-grow bg-white h-[calc(100vh-3rem)]">
                <Routes>
                  <Route path="/" element={<MultiMap />} />
                  <Route path="/devices" element={<DevicesList />} />
                  <Route path="/tasks" element={<TaskManagerWrapper />} />
                  <Route path="/maker" element={<MarkerMaker />} />
                  <Route path="/insights" element={<Insights />} />
                </Routes>
              </div>
            </div>
          </div>
        )}
        <Notifications />
      </Router>
    </Provider>
  );
};

const LeftNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="bg-dark-blue flex flex-col items-center w-52 p-5">
      <div className="mb-5 w-full flex justify-center">
        <img src="/logotype-waltero.svg" alt="Waltero Logo" className="w-full max-w-xs" />
      </div>

      <div className="w-full space-y-2">
        <RegularButton
          text="Map View"
          colorScheme="dark"
          selected={location.pathname === '/'}
          onClick={() => navigate('/')}
          className="justify-start"
        />

        <RegularButton
          text="Devices"
          colorScheme="dark"
          selected={location.pathname === '/devices'}
          onClick={() => navigate('/devices')}
          className="justify-start"
        />

        <RegularButton
          text="Task Manager"
          colorScheme="dark"
          selected={location.pathname === '/tasks'}
          onClick={() => navigate('/tasks')}
          className="justify-start"
        />

        <RegularButton
          text="Insights"
          colorScheme="dark"
          selected={location.pathname === '/insights'}
          onClick={() => navigate('/insights')}
          className="justify-start"
        />
      </div>
    </div>
  );
};

const TopBar = () => {
  return (
    <div className="h-12 bg-blue-700 text-white flex items-center justify-between">
      <Dashboard />
    </div>
  );
};

const Page1 = () => <div className="bg-content-bg p-5 rounded">Devices Page Coming Soon</div>;
const Page2 = () => <div className="bg-content-bg p-5 rounded">Taskmanager Page Coming Soon</div>;

export default App;
